<template>
    <div class="container-fluid">
        <div id="success" :class="{visible: true}">
            <font-awesome-icon :icon="['fal', 'check-circle']" />
        </div>
    </div>
</template>

<script type="text/javascript">
	import Vue from 'vue'
	import Tools from "@/mixins/Tools.js";
    import Navigation from "@/mixins/Navigation.js";
    import _debounce from 'lodash/debounce'

	export default {
		mixins: [Tools, Navigation],
		props: [
            'redirectionUrl', // redirection à effectuer après le debounced
            'redirectionFunc', // fonction exécutée après le debounced
            'debounced',   // durée d'affichage du Success avant la redirection
 		],
		data() {
			return {
                debounced_local: '1000'
            }
		},
        created: function() {
            // Par défaut, on donne un délai d'une seconde (1000 millisecondes)
            if(this.debounced) {
                this.debounced_local = this.debounced
            }

            // On créé notre méthode debounced
            this.debouncedRedirect = _debounce(this.redirect, this.debounced_local)
        },
		mounted() {
            this.debouncedRedirect()
		},
		methods: {
            redirect() {
                if(this.redirectionUrl) {
                    this.$router.push(this.redirectionUrl)
                }
                else if(this.redirectionFunc) {
                    this.redirectionFunc()
                }
                else {
                    this.goBack()
                }
            }
		},
		computed: {

		}
	}
</script>
